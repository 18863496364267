import React, { useState } from 'react';

import Map from 'common/components/Map';
import { CoordsType } from 'common/types';
import { useCheckSupportedZone, useLayout } from 'service/hooks';
import { usePersistedSupportedDeliveryZone, usePersistedSelectedCoordinates, useSelectAddressOrArea } from 'common/hooks';
import { customHistory, replaceRoute } from 'common/utils';
import { URLS } from 'common/constants';

const DeliveryMap: React.FC = () => {
  const [persistedSupportedDeliveryZone] = usePersistedSupportedDeliveryZone();
  const [selectedCoords] = usePersistedSelectedCoordinates();

  const handleRedirection = (branchId: string): void => {
    const prevPath = customHistory.getPrevPath();
    if (prevPath === URLS.CHECKOUT || prevPath === URLS.ADDRESS_BOOK) replaceRoute(URLS.NEW_ADDRESS);
    else customHistory.handleOrderModeRouting({ branchId });
  };

  const { handleOnDeliveryZoneSelected, handleSelectCoordinates } = useSelectAddressOrArea(handleRedirection);

  const [geoPoint, setGeoPoint] = useState([selectedCoords?.lng || 29.363895747, selectedCoords?.lat || 47.983437979]);

  const { data: layoutSettings } = useLayout();
  const checkSupportedZoneVars =
    layoutSettings?.id && geoPoint.length > 1 ? { restaurantReferenceId: layoutSettings?.id, geoPoint } : null;
  const { data: supportedZoneResponse, isLoading: isLoadingSupportedZone } = useCheckSupportedZone(checkSupportedZoneVars);
  const { supportedDeliveryZone } = supportedZoneResponse || { supportedDeliveryZone: null };

  const handleOnCoordsChange = (coords: CoordsType) => setGeoPoint([coords.lng, coords.lat]);

  const handleOnSave = async (coords: CoordsType) => {
    const handleDeliveryZoneSelection = (coordinates: CoordsType) => {
      handleSelectCoordinates(coordinates);

      if (supportedDeliveryZone.id === persistedSupportedDeliveryZone?.id) {
        customHistory.back();
        return;
      }

      handleOnDeliveryZoneSelected({
        ...supportedDeliveryZone,
        id: supportedDeliveryZone?.id,
        countryId: supportedDeliveryZone?.countryId,
        minimumOrder: supportedDeliveryZone?.minimumOrder,
        deliveryFee: supportedDeliveryZone?.deliveryFee,
        deliveryTime: supportedDeliveryZone?.deliveryTime,
      });
    };

    handleDeliveryZoneSelection(coords);
  };

  return (
    <Map
      handleOnConfirm={handleOnSave}
      handleOnCoordsChange={handleOnCoordsChange}
      actionButton={{ disabled: !supportedDeliveryZone, isLoading: isLoadingSupportedZone }}
    />
  );
};

export default DeliveryMap;
