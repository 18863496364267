import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';

import { StyledPageWrapper, StyledStickyButton } from 'common/styles';
import {
  useIsKuwait,
  usePersistedAddressArea,
  usePersistedCountryDetails,
  usePersistedSelectedCoordinates,
} from 'common/hooks';
import { CoordsType } from 'common/types';
import { logger, useAllowLocation } from 'common/utils';
import { LANGUAGE_AR } from 'common/constants';
import { useRouter } from 'next/router';
import { NAVIGATOR_PERMISSION_STATES } from 'common/enums';
import { getDefaultCoords } from 'modules/userModule/utils/getDefaultCoords';
import { useCountryDetails } from 'service/hooks';
import ServiceConfigs from 'service/config';
import { useFulfillmentSettings } from '../../../contexts';
import PaciMap from '../Paci';
import { fetchAreaDetailsByCoordinates } from '../Paci/utils';
import OpenStreetMap from '../OpenStreetMap';

type MapProps = {
  handleOnConfirm?: (currentAreaCoords: CoordsType) => void;
  handleOnCoordsChange?: (currentAreaCoords: CoordsType) => void;
  actionButton?: { disabled: boolean; isLoading: boolean };
};

const Map: React.FC<MapProps> = ({
  handleOnConfirm,
  handleOnCoordsChange,
  actionButton = { disabled: false, isLoading: false },
}) => {
  const { t, lang } = useTranslation('address');

  const [selectedCoordinates] = usePersistedSelectedCoordinates();
  const [persistedAddressArea] = usePersistedAddressArea();
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const { data: countryDetails } = useCountryDetails({ id: persistedCountryDetails?.id });
  const multicountryEnabled = ServiceConfigs.getMulticountryEnabled();

  const [areaDetails, setAreaDetails] = useState({
    title: ['', ''],
    description: ['', ''],
  });

  const { branch: branchDetails, isBranchLoading: isBranchDetailsLoading } = useFulfillmentSettings();

  const isKuwait = useIsKuwait();
  const defaultCoords = getDefaultCoords(countryDetails?.code);

  const [currentAreaCoords, setCurrentAreaCoords] = useState<CoordsType>({
    lat: selectedCoordinates?.lat || branchDetails?.lat || defaultCoords.lat,
    lng: selectedCoordinates?.lng || branchDetails?.lng || defaultCoords.lng,
  });

  const handleSelectCurCoords = coords => {
    handleOnCoordsChange(coords);
    setCurrentAreaCoords(coords);
    setIsStarting(false);
  };

  const { isLoading: isFetchingUserLocation, locationPermissionState } = useAllowLocation(handleSelectCurCoords);

  const [isStarting, setIsStarting] = useState<boolean>(true);
  const router = useRouter();
  const [zoomLvl, setZoomLvl] = useState<number>(Number(router.query?.zoom) || 9);
  const locationGranted = locationPermissionState === NAVIGATOR_PERMISSION_STATES.GRANTED;

  useEffect(() => {
    if (locationGranted) {
      setIsStarting(false);
      setZoomLvl(16);
    }
  }, [locationGranted]);

  const displaySaveButtonText = () => {
    if (isStarting) return t('moveThePin');
    if (actionButton?.disabled) return t('weDontDeliverHere');
    return t('save');
  };

  const updateAreaDetails = async (coords: CoordsType) => {
    try {
      await fetchAreaDetailsByCoordinates(coords.lat, coords.lng, lang).then((res: any) => {
        setAreaDetails({
          title: [res.TitleEnglish, res.TitleArabic],
          description: [res.DetailsEnglish, res.DetailsArabic],
        });
      });
    } catch (error) {
      logger.error({ error: 'PACI Error, searching data by coordinates', extraData: error });
    }
  };

  return (
    <StyledPageWrapper>
      {isKuwait && !multicountryEnabled ? (
        <PaciMap
          center={currentAreaCoords}
          onDragEndOrAreaSelect={newCoords => {
            handleSelectCurCoords(newCoords);
            updateAreaDetails(newCoords);
          }}
          zoom={zoomLvl}
        />
      ) : (
        <OpenStreetMap
          center={{
            lat: currentAreaCoords?.lat || persistedAddressArea?.lat || persistedCountryDetails?.lat,
            lng: currentAreaCoords?.lng || persistedAddressArea?.lng || persistedCountryDetails?.lng,
          }}
          onDragEnd={(coords: CoordsType) => {
            handleSelectCurCoords(coords);
          }}
          zoom={zoomLvl}
          hasZoomControl={false}
          hasCurrLocationButton={locationGranted}
        />
      )}
      <StyledStickyButton>
        {isKuwait && !isStarting && !multicountryEnabled && (
          <div style={{ width: '100%' }}>
            <Typography variant="element16" color="grey">
              {t('deliveryLocation')}
            </Typography>
            <Typography variant="heading18" my={6}>
              {actionButton.isLoading || isBranchDetailsLoading ? `...` : areaDetails.title[lang === LANGUAGE_AR ? 1 : 0]}
            </Typography>
            <Typography variant="body16">
              {actionButton.isLoading || isBranchDetailsLoading ? `...` : areaDetails.description[lang === LANGUAGE_AR ? 1 : 0]}
            </Typography>
          </div>
        )}
        <Button
          testId="save_location_on_map_btn"
          text={displaySaveButtonText()}
          isFluid
          isLoading={actionButton.isLoading || isBranchDetailsLoading || isFetchingUserLocation}
          isDisabled={actionButton.disabled || isFetchingUserLocation || isStarting}
          onClick={() => handleOnConfirm(currentAreaCoords)}
          type="submit"
        />
      </StyledStickyButton>
    </StyledPageWrapper>
  );
};

export default Map;
